@font-face {
    font-family: "Montserrat-Regular";
    src: url("../fonts/Montserrat-Regular.ttf");
}
@font-face {
    font-family: "Amalfi-Coast";
    src: url("../fonts/Amalfi-Coast.ttf");
}
@font-face {
    font-family: "AlethiaPro-Regular";
    src: url("../fonts/AlethiaPro-Regular.otf");
}
header{
    margin: 20px 0;
    padding: 0 15px;
}
.navbar img{
    min-width: 40px;
}
.navbar a{
    font-family: "Montserrat";
}
.height-board{
    height: 100vh;
    display: flex;
}
.chartboard{
    padding: 30px;
    background: #202124;
    font-family: "Montserrat";
    justify-content: center;
    width: 100%;
    margin: 20px 0;
}
.chartboard h4, .chartboard h3{
    color: #a6a6a7;
    font-size: 26px;
}
.chartboard h3:last-child {
    color: #df8d6b;
}
.card-header{
    text-align: left;
    color: #cecccf;
    font-weight: 700;
    font-size: 26px;
}
.card-header p{
    font-size: 3rem;
}
.card-header h3{
    font-size: 3rem;
}

.chartboard .card-body{
    background-color: #2c2d32;
    border-radius: 0.4rem;
    text-align: left;
    padding: 25px;
    display: flex;
    align-items: center;
}
.grid-chart{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    height: 100%;
    gap: 40px 25px;
    grid-template-columns: repeat(3, auto);
}
.grid-chart .chart-item img{
    color: #b7846f;
    height: 100%;
    padding: 15px;
}
.grid-chart .chart-item .img-card{
    display: inline-block;
    background: #b7846f45;
    height: 80px;
    border-radius: 100%;
    animation: shadow 3s ease-out infinite;
    animation-delay: 2s;
}
.grid-chart .chart-item p{
    display: inline-block;
    font-size: 44px;
    font-weight: 700;
    background: -webkit-linear-gradient(#925f48, #e8c8b2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 0 1.6rem!important;
    vertical-align: middle;
}
.grid-chart .chart-item .content{
    margin-top: 15px;
}
.grid-chart .chart-item .content p{
    font-size: 2rem;
    font-weight: normal;
    margin: 0!important;
    display: none;
}
.date p {
    color: #d4d4d4;
    box-shadow: 0px 0px 30px 0px rgb(237 237 237 / 45%);
    padding: 0.4rem 2.3rem;
    font-size: 18px;
    margin: 0;
    border-radius: 13px;
}

@keyframes shadow {
    0% {
      box-shadow: 0 0 0 0 rgb(222 139 105);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(55, 141, 250, 0);
    }
  }

  
.date{
    /* position: absolute;
    top: 30px;
    right: 30px; */
    z-index: 999;
}
.announcesingle .banner{
    width: 100%;
    height: calc(100vh);
    display: flex;
    align-items: center;
}
.announcesingle .banner .img{
    background-image: url(https://upload-5b6ed3f….s3.amazonaws.com/main/eea/eeab47d…/photo_2022-08-10_16-36-06.jpg);
    background-size: 100%;
    display: block;
    overflow: auto;
    height: 57vh;
    width: 32vw;
    background-repeat: no-repeat;
    margin: 0 auto;
    /* box-shadow: inset 0 0 30px 4px #000; */ 
    border-radius: 20px;
}
.context{
    --color-1: #ec03b3;
    --color-2: #f7098b;
    --color-3: #ff2058;
    --color-4: #ff4f31;
    --color-5: #fe7c0b;
    font-family: "Montserrat";
    text-align: left;
}
.context p.upper{
    /* background: linear-gradient(219deg, var(--color-1) 19%, transparent 19%,transparent 20%, var(--color-2) 20%, var(--color-2) 39%, transparent 39%,transparent 40%, var(--color-3) 40%,var(--color-3) 59% , transparent 59%,transparent 60%, var(--color-4) 60%, var(--color-4) 79%, transparent 79%, transparent 80%, var(--color-5) 80%); */
    background-clip: text;
    -webkit-background-clip: text;
    color: #b7846f;
    font-size: 28px;
    margin: 0;
}
.context h3{
    color: #fff;
    font-weight: bold;
    font-size: 32px;
}
.context p{
    color: #9a9b9b;
    font-size: 20px;
}
.content-block{
    box-shadow: 0px -43px 100px -20px #000;
    position: relative;
    z-index: 9999999;
}
.context {
    color: gold;
}
.context .price{
    color: #ffc83d;
    font-size: 38px;
    font-weight: 600;
}
.logoblock{
    /* position: absolute;
    top: 30px;
    left: 30px; */
    max-width: 70px;
}
.logoblock img{
    width: 100%;
}
#myChart canvas {
    font-size: 250px;
}
.radar canvas{
    width: 100%!important;
}

.doughn canvas{
    width: 50%!important;
    height: auto!important;
    margin: 0 auto;
}
.timing {
    font-size: 36px;
    color: #fff;
    font-family: 'Montserrat';
    margin-bottom: 5px;
}
.clock-item {
    border: 3px solid transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.uae{
    border-color: #df8d6b;
}

.owl-carousel {
    height: calc(100vh - 105px);
    overflow: hidden;
}



.lists ul li{
    border-bottom: 1px solid #525151;
    color: #d9d9d9;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 3px 0;
}
.lists ul li:last-child{
    border: none;
}
.list-board{
    padding: 30px;
    background: #202124;
    font-family: "Montserrat";
    height: calc(100vh - 50px);
    width: 100%;
}
.list-board h3{
    text-align: center;
    color: #d9d9d9;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 30px;
}
.list-board h3:after {
    content: "";
    background: #df8d6b;
    width: 300px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -15px;
}

body{
    background: #000;
  }


  .goal-block{
    position: relative;
    text-align: center;
  }
  .goal-block p{ 
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    top: 0px;
    font-size: 26px;
  }

  .grid-custom{
    display: grid;
    grid-template-columns: 60% 40%;
  }
  .deal-type{font-size: 28px!important;}
.lists ul {
    padding: 0;
    margin: 0;
}
.dealday{
    overflow: hidden;
}
.dealday .col-md-4:nth-child(1) h3::after {
    background: red;
    height: 6px;
}
.dealday .col-md-4:nth-child(2) h3::after {
    background: yellow;
    height: 6px;
}
.dealday .col-md-4:nth-child(3) h3::after {
    background: green;
    height: 6px;
}

/* .deal-1{
    background: rgba(255, 0, 0, 0.3);
}
.deal-2{
    background: rgba(255,207,0, 0.3);
}
.deal-3{
    background: #174023;
} */


.list-board h3.title {
    font-size: 34px;
}
.list-board h3.title:after {
    width: 95%;
}

.logo svg{
    width: 100%;
}
.target{
    text-align: center;
}
.target .text{
    font-family: 'Raleway';
}
.target .text.title{
    font-size: 78px;
    color: #fff;
    line-height: 70px
}
.target .text.content{
    font-size: 28px;
    color: #fff;
    line-height: 33px;
    margin: 0 132px;
}


.secondary-frame{
    height: 100vh;
    display: flex;
    align-items: center;
}
.secondary-frame iframe{
    width: 100%;
    height: 90vh;
    transform: scale(1);
}

.org-chart img {
    width: auto;
    height: 100vh;
    margin: 0 auto;
    display: block;
}


.new-employees {
    font-family: "Montserrat";
}
.new-employees h2{
    color: #fff;
    text-align: center;
    margin: 20px 0 0px 0;
    text-transform: uppercase;
    font-size: 34px;
}
.new-employees__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 5 столбцов */
    grid-template-rows: repeat(3, minmax(0, 1fr)); /* 3 строки, равномерно распределенные по высоте */
    row-gap: 16px; /* Расстояние между блоками */
    padding: 16px; /* Отступы вокруг сетки */
    max-height: 100vh; /* Максимальная высота экрана */
    box-sizing: border-box; /* Включает padding и border в общую высоту */
  }
  
  .new-employees__item {
    /* background-color: #f9f9f9; Цвет фона блока */
    /* border: 1px solid #ddd; Граница блока */
    border-radius: 8px; /* Скругление углов блока */
    padding: 5px; /* Внутренние отступы блока */
    text-align: center; /* Центрирование текста */
    display: flex; /* Flexbox для центрирования содержимого */
    flex-direction: column; /* Расположение содержимого вертикально */
    justify-content: center; /* Центрирование по вертикали */
  }
  
  .new-employees__item img {
    width: 80%; /* Ширина изображения */
    height: auto; /* Автоматическая высота изображения */
    border-radius: 5%; /* Скругление изображения для создания аватарки */
    margin-bottom: 8px; /* Отступ под изображением */
    align-self: center; /* Центрирование изображения */
  }
  
  .new-employees__item h3 {
    font-size: 20px; /* Размер шрифта заголовка */
    margin: 10px 0 0 0;
    color: #fff; /* Цвет текста заголовка */
  }
  

  body {
    line-height: normal;
}

.active{
    color: #62ae41;
}
.inactive{
    color: #F81F58
}
.input-edit-slide{
    height: 30px;
}

.happe-text{
    text-align: center;
    font-size: 72px;
    font-family: 'Montserrat';
    color: #fff;
    margin-bottom: 0;
}
.happy-banner .banner{
    height: calc(100vh - 220px)!important;
}
.happy-banner .context p {
    font-size: 28px;
}
.happy-banner .context p.price{
    font-size: 40px;
}
.happy-banner .context h3 {
    font-size: 32px;
}

.birthday-slide {
    background: linear-gradient(30deg, #7E4634, #000000, #7E4634);
}
.birthday-slide .container{
    max-width: 1680px;
    padding: 0 120px;
    overflow: hidden;
    height: 100vh;
    align-items: center;
    display: flex;
}
.birthday-slide h1{
    margin: 0;
    font-size: 141px;
    font-family: "AlethiaPro-Regular";
}
.birthday-slide h2{
    font-size: 120px;
    font-family: "Amalfi-Coast";
    line-height: 2;
}
.birthday-slide .content{
    color: #fff;
    text-align: center;
}
.birthday-slide .content h3{
    font-family: "Montserrat-Regular";
    font-weight: bold;
    font-size: 47px;
    margin-top: 60px;
}
.birthday-slide .content p{
    font-family: "Montserrat-Regular";
    font-size: 33px;
}
.birthday-slide .content img{
    margin-bottom: 50px;
}
.agent-img img{
    width: 100%;
    background: linear-gradient(270deg, #b65a39, #ffddc3);
    padding: 5px;
}
